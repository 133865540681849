import { initializeApp } from "firebase/app";
import { getAuth } from "firebase/auth";
import { getFirestore } from "firebase/firestore";
import { getFunctions } from "firebase/functions";

const firebaseConfig = {
    apiKey: "AIzaSyAhzkD0YgGnQk3jFqsbrJKHuVjc8VscecI",
    authDomain: "messaging-automation-tool.firebaseapp.com",
    databaseURL:
        "https://messaging-automation-tool-default-rtdb.firebaseio.com",
    projectId: "messaging-automation-tool",
    storageBucket: "messaging-automation-tool.appspot.com",
    messagingSenderId: "120825528677",
    appId: "1:120825528677:web:758143ff48dba146b0218c",
    measurementId: "G-T39ZQ3GDK0"
};

export const app = initializeApp(firebaseConfig);
export const auth = getAuth(app);
export const db = getFirestore(app);
export const functions = getFunctions();
