import "../scss/main.scss";

import { onAuthStateChanged } from "firebase/auth";
import { auth } from "./firebase";

const params = new Proxy(new URLSearchParams(window.location.search), {
    get: (searchParams, prop: string) => searchParams.get(prop),
    has: (searchParams, prop: string) => searchParams.has(prop)
});

onAuthStateChanged(auth, async (user) => {
    const { Menu, showMenu } = await import("./ui");
    if (user) {
        const { menuHeaderUser } = await import("./ui-elements");
        if (menuHeaderUser) {
            menuHeaderUser.textContent = `${user.displayName} (${user.email})`;
        }
        showMenu(Menu.account);
    } else {
        showMenu(Menu.signin);
    }
});

switch (window.location.pathname) {
    case "/checkout.html":
        routeCheckout();
        break;
    case "/manage.html":
        routeManage();
        break;
    case "/status.html":
        routeStatus();
        break;
}

async function routeCheckout() {
    const { onPurchaseError, purchase } = await import("./purchase");
    if ("price-id" in params) {
        const priceId = <string>params["price-id"];
        onAuthStateChanged(auth, (user) => {
            if (user) {
                purchase(user.uid, priceId, true);
            }
        });
    } else {
        onPurchaseError();
    }
}

function routeManage() {
    onAuthStateChanged(auth, async (user) => {
        if (user) {
            const { manage } = await import("./manage");
            manage(true);
        }
    });
}

async function routeStatus() {
    if ("state" in params) {
        const status = <string>params["state"];
        if (status === "success") {
            const { statusCheckmark, statusHeader, statusBody } = await import(
                "./ui-elements"
            );
            statusCheckmark?.classList.remove("d-none");
            if (statusHeader) {
                statusHeader.textContent = "Thank you!";
            }
            if (statusBody) {
                statusBody.innerHTML =
                    "Please <a href='/user_manual.html'>read the user manual</a> and <a href='/#downloads'>download the application</a> to get started.";
            }
            return;
        }
    }
    window.location.assign("/");
}
